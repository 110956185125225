<script>
import Layout from "../../layouts/main.vue";
import store from "@/state/store";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      schedules: [],
      msg: "",
      permission: store.getters["login/permission"],
    };
  },
  methods: {
    updateData(id) {
      if (id) {
        this.$router.push("/admin/update-availability/" + id);
      } else {
        this.$router.push("/admin/add-availability")
      }
    },
    async getData() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/doctors/schedule"
      );
      this.schedules = response.data;
      console.log(this.schedules);
    },
    addButton() {
      this.$router.push({
        path: '/admin/add-availability  '
      });
    },
    async deleteSchedule(id) {
      const user = await axios.delete(
        "https://api.doctosoft.com/index.php/api/doctors/schedule/" + id
      );
      this.msg = user.data;
      this.getData();
    },
    async changeStatus(schedule) {
      var urlencoded = new URLSearchParams();
      urlencoded.append("status", !schedule.status? 1 : 0) ;
      console.log(urlencoded);
      await axios.put(
        "https://api.doctosoft.com/index.php/api/doctors/schedule/" + schedule.id,
        urlencoded
      );
      console.log("hello")
      this.getData();
    },
    confirm(id) {
      Swal.fire({

        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value === true) {
          Swal.fire("Deleted!", "Deleted a Schedule successfully with id " + id, "success");
          this.deleteSchedule(id)

        }
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<template>
  <Layout>
    <b-row>
      <b-col lg="12">
        <b-card no-body>
          <b-card-header class="d-flex align-items-center">
            <h5 class="card-title flex-grow-1 mb-0">Doctor's Availability</h5>
            <div class="d-flex gap-1 flex-wrap">
              <b-button
                variant="soft-danger"
                id="remove-actions"
                onClick="deleteMultiple()"
                ><i class="ri-delete-bin-2-line"></i>
              </b-button>
              <b-link
                class="btn btn-success button create-btn"
                type="button"
                id="docadd-btn"
                @click="addButton"
                ><i class="ri-add-line align-bottom me-1"></i> Add
              </b-link>
            </div>
          </b-card-header>
          <b-card-body>
            <div>
              <div class="table-responsive table-card mb-3">
                <table class="table align-middle table-nowrap mb-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col" style="width: 50px">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="checkAll"
                            value="option"
                          />
                        </div>
                      </th>
                      <th class="sort" data-sort="name" scope="col">Doctors</th>
                      <th class="sort" data-sort="excludedDate" scope="col">
                        excludedDate
                      </th>
                      <th class="sort" data-sort="day" scope="col">day</th>
                      <th class="sort" data-sort="startTime" scope="col">
                        startTime
                      </th>
                      <th class="sort" data-sort="endTime" scope="col">
                        endTime
                      </th>
                      <th class="sort" data-sort="consultingTime" scope="col">
                        consultingTime
                      </th>
                      <th class="sort" data-sort="status" scope="col">
                        status
                      </th>

                      <th
                        scope="col"
                        v-if="
                          this.permission.doctorAvailability.update ||
                          this.permission.doctorAvailability.delete
                        "
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                    <tr v-for="schedule in schedules" :key="schedule.id">
                      <th scope="row">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="chk_child"
                            value="option1"
                          />
                        </div>
                      </th>
                      <td class="name">{{ schedule.doctors }}</td>
                      <td class="email">{{ schedule.excludedDate }}</td>
                      <td class="image">{{ schedule.day }}</td>
                      <td class="phone">{{ schedule.startTime }}</td>
                      <td class="address">{{ schedule.endTime }}</td>
                      <td class="expert">{{ schedule.consultingTime }}</td>
                      <td class="status">
                        <b-badge
                          variant="badge-soft-success"
                          :class="{
                            'badge-soft-success': schedule.status == true,
                            'badge-soft-danger': schedule.status == false,
                          }"
                          >{{ schedule.status }}</b-badge
                        >
                      </td>

                      <div class="dropdown">
                        <b-button
                          variant="soft-secondary"
                          size="sm"
                          class="dropdown"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="ri-more-fill align-middle"></i>
                        </b-button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <b-link
                              class="dropdown-item edit-item-btn"
                              id="edit-item-btn"
                              @click="updateData(schedule.id)"
                              >Edit</b-link
                            >
                          </li>
                          <li>
                            <b-button
                              class="dropdown-item disable-btn"
                              id="disable-btn"
                              @click="changeStatus(schedule)"
                              >{{ schedule.status ? "Disable" : "Active" }}
                            </b-button>
                          </li>
                          <li>
                            <b-button
                              class="dropdown-item remove-item-btn"
                              @click="confirm(schedule.id)"
                              >Delete</b-button
                            >
                          </li>
                        </ul>
                      </div>
                    </tr>
                  </tbody>
                </table>
                <!-- <div class="noresult" style="display: none">
                        <div class="text-center">
                          <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                            :height="75" :width="75" />
                          <h5 class="mt-2">Sorry! No Result Found</h5>
                          <p class="text-muted mb-0">We've searched more than 150+ API Keys We did not find any API for you
                            search.</p>
                        </div>
                      </div> -->
              </div>
              <!-- <div class="d-flex justify-content-end mt-3">
                      <div class="pagination-wrap hstack gap-2">
                        <b-link class="page-item pagination-prev disabled" href="#" v-if="page != 1" @click="page--">
                          Previous
                        </b-link>
                        <ul class="pagination listjs-pagination mb-0">
                          <li :class="{ active: pageNumber == page, disabled: pageNumber == '...', }"
                            v-for="(pageNumber, index) in pages" :key="index" @click="page = pageNumber">
                            <b-link class="page" href="#">{{ pageNumber }}</b-link>
                          </li>
                        </ul>
                        <b-link class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                          Next
                        </b-link>
                      </div> -->
              <!-- </div> -->
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>
